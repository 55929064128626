import moment from "moment";
import "moment/locale/ro";
import { Cart } from "@/common/entities/cart/CartItem";
import { CheckoutCart, CheckoutCartConfigurator } from "@/components/Cart/CartForm/CartForm";

moment.locale("ro");

export const MonthTimestamp = 3600 * 24 * 31;

export const middleStringEllipsis = (val: string, size = 5): string => {
    return val ? val.substring(0, size) + " ... " + val.slice(-size) : val;
};

export const endStringEllipsis = (val: string, size = 25): string => {
    if (!val || val.length <= size) {
        return val;
    }

    return val.substring(0, size) + "...";
};

export const getDateFromTimestamp = (timestamp: number): string | null => {
    if (!timestamp) {
        return null;
    }

    return moment(timestamp * 1000).format("D MMMM | HH:MM");
};

export function slugify(str: string) {
    return String(str)
        .normalize("NFKD") // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
        .replace(/\s+/g, "-") // replace spaces with hyphens
        .replace(/-+/g, "-"); // remove consecutive hyphens
}

export const formatNumberWithDecimals = (number: number | string, decimals: number = 2) => {
    const num = Number(number);
    return num.toLocaleString("de-DE", {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
};

export const extractNumber = (string: string) => {
    const numsStr = string.replace(/[^0-9]/g, "");
    return parseInt(numsStr);
};

export const ALL_NUMBERS_STRING = "0123456789";
export const ALL_LETTERS_STRING = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export function generateRandomString(length: number, characters: string = ALL_LETTERS_STRING): string {
    let result = "";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }

    return result;
}

export function generateFullCheckoutCart(cart: Cart): CheckoutCart {
    if(!cart?.cartProducts?.length) {
        return cart as CheckoutCart;
    }

    const configuratorsDetails: CheckoutCartConfigurator[] = [];

    const configuratorsCartProducts = cart.cartProducts
        .filter((cartProduct) => cartProduct.originConfiguratorCode)
        .map((cartProduct) => cartProduct.originConfiguratorCode);
    if (configuratorsCartProducts.length) {
        const configuratorsCodes = Array.from(new Set(configuratorsCartProducts));

        for (const configuratorCode of configuratorsCodes) {
            const cartProducts = cart.cartProducts.filter((cartProduct) => cartProduct.originConfiguratorCode === configuratorCode);
            if (!cartProducts.length) {
                continue;
            }

            if (cartProducts[0].originConfiguratorCode && cartProducts[0].originConfiguratorName && cartProducts[0].originConfigurator) {
                const newConfiguratorsCartsProducts: CheckoutCartConfigurator = {
                    cartProducts: cartProducts,
                    configurator: cartProducts[0].originConfigurator,
                    configuratorCode: cartProducts[0].originConfiguratorCode,
                    configuratorName: cartProducts[0].originConfiguratorName,
                };

                configuratorsDetails.push(newConfiguratorsCartsProducts);
            }
        }
    }

    const newCart: CheckoutCart = { ...cart, configurators: [] };

    newCart.cartProducts = cart.cartProducts.filter((cartProduct) => !cartProduct.originConfiguratorCode);
    newCart.configurators = configuratorsDetails;
    return newCart;
}