import {ReadonlyURLSearchParams} from "next/dist/client/components/navigation";

class UrlQueryUtils {
    public static getInitialCurrentPage(urlQuery: any, rows = 25) {
        return urlQuery.has("pages") ? (Number(urlQuery.get("pages")) - 1) * rows : 0;
    }

    public static getDisplayPage(urlQuery: any) {
        return urlQuery.has("pages") ? Number(urlQuery.get("pages")) : 1;
    }

    public static getSearchParamValue(urlQuery: ReadonlyURLSearchParams, param: string): string | undefined {
        const valueParam = urlQuery.get(param);
        if (!valueParam) {
            return undefined;
        }

        return valueParam;
    }
}

export default UrlQueryUtils;
