import Image from "next/image";
import { awsS3BaseURL } from "@/lib/Constants";

export interface ImageWrapperProps {
    url?: string;
    title: string;
    width: number;
    height: number;
    baseURL?: string;
    className?: any;
}

export const ImageWrapper = ({ url, title, width, height, baseURL = awsS3BaseURL, className = "" }: ImageWrapperProps) => {
    if (!url) {
        return <Image priority={true} className={`img-fluid ${className}`} src={"/categoryImageBlur.webp"} alt={title} width={width} height={height} />;
    }

    return (
        <Image
            sizes="100vw"
            width={width}
            loading={"lazy"}
            height={height}
            className={`img-fluid ${className}`}
            src={baseURL + url}
            placeholder="blur"
            blurDataURL="/categoryImageBlur.webp"
            alt={title}
            unoptimized
        />
    );
};
